import { StringUtils } from "utilities/string-utils";
import { RoleType } from "models/enumerations/users/role-type";
import { REPORT_TYPES } from "components/reports/report-all-trainings/report-all-trainings-constants";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { CollectionUtils } from "utilities/collection-utils";
import { InstructorRecord } from "models/view-models/instructors/instructor-record";
import { INVOICE_STATUS_TYPES } from "components/reports/components/report-invoice-status-selection/report-invoice-status-constants";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportAllTrainingsState {
    reportType: string;
    instructors: InstructorRecord[];
    organizations: OrganizationRecord[];
    invoiceStatusType: string;
    dateRangeStart: Date | null;
    dateRangeEnd: Date | null;
}

type ReducerActions =
    | { type: "reportType"; reportType: string }
    | { type: "invoiceStatusType"; invoiceStatusType: string }
    | { type: "dateRange"; dateRangeStart: Date | null; dateRangeEnd: Date | null }
    | { type: "updateOrganizations"; organizations: OrganizationRecord[] }
    | { type: "updateInstructors"; instructors: InstructorRecord[] };

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Reducer
// -------------------------------------------------------------------------------------------------

const initialState: ReportAllTrainingsState = {
    reportType: "",
    invoiceStatusType: INVOICE_STATUS_TYPES.NOT_INVOICED,
    organizations: [],
    instructors: [],
    dateRangeStart: null,
    dateRangeEnd: null,
};

const validate = (state: ReportAllTrainingsState): boolean => {
    const { reportType } = state;

    if (!StringUtils.hasValue(reportType)) {
        return false;
    }

    if (reportType === REPORT_TYPES.ORGANIZATION) {
        return CollectionUtils.hasValues(state.organizations);
    }

    if (reportType === REPORT_TYPES.EN_INSTRUCTOR) {
        return CollectionUtils.hasValues(state.instructors);
    }

    if (reportType === REPORT_TYPES.ALL_TRAININGS) {
        return state.dateRangeStart != null && state.dateRangeEnd != null;
    }

    return true;
};

const reducer = (
    state: ReportAllTrainingsState,
    action: ReducerActions
): ReportAllTrainingsState => {
    switch (action.type) {
        case "reportType":
            const { reportType } = action;

            return {
                ...state,
                organizations: reportType === REPORT_TYPES.ORGANIZATION ? state.organizations : [],
                instructors: reportType === REPORT_TYPES.EN_INSTRUCTOR ? state.instructors : [],
                reportType,
            };
        case "invoiceStatusType":
            const { invoiceStatusType } = action;

            return { ...state, invoiceStatusType };
        case "updateOrganizations":
            const { organizations } = action;

            return { ...state, organizations };
        case "updateInstructors":
            const { instructors } = action;

            return { ...state, instructors };
        case "dateRange":
            const { dateRangeStart, dateRangeEnd } = action;

            return {
                ...state,
                dateRangeStart,
                dateRangeEnd,
            };
        default:
            throw new Error();
    }
};

const initializer =
    (roleType?: RoleType | null,
    organization?: OrganizationRecord | null) =>
    (initialState: ReportAllTrainingsState): ReportAllTrainingsState => {
        if (roleType === RoleType.ClientAdmin) {
            return {
                ...initialState,
                reportType: REPORT_TYPES.ORGANIZATION,
                organizations: organization ? [organization] : [],
            };
        }
        if (
            roleType === RoleType.Instructor ||
            roleType === RoleType.AenAdministrator
        ) {
            return {
                ...initialState,
                reportType: REPORT_TYPES.ALL_TRAININGS,
            };
        }

        return initialState;
    };

// #endregion Reducer
// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------
export { initialState, initializer, reducer, validate };
export type { ReportAllTrainingsState };

// #endregion Exports
