import { CollectionUtils, RecordUtils } from "andculturecode-javascript-core";
import { GroupUserRecord } from "models/view-models/groups/group-user-record";
import { InstructorProfileRecord } from "../instructors/instructor-profile-record";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import { Record } from "immutable";
import { StringUtils } from "andculturecode-javascript-core";
import { User } from "models/interfaces/users/user";
import { UserRoleRecord } from "models/view-models/user-roles/user-role-record";
import { DateUtils } from "utilities/date-utils";
import { UserLoginRecord } from "./user-login-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: User = {
    createdById: undefined,
    createdOn: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    city: undefined,
    country: undefined,
    email: undefined,
    externalIdentifier: undefined,
    firstName: "",
    groupUsers: undefined,
    id: undefined,
    instructorProfile: undefined,
    isSuperAdmin: false,
    lastLogin: undefined,
    lastName: "",
    netsuiteId: undefined,
    nfpaId: undefined,
    nfpaWebId: undefined,
    organization: undefined,
    organizationId: undefined,
    phoneNumber: undefined,
    preferredLanguage: undefined,
    provider: undefined,
    providerId: undefined,
    state: undefined,
    userLogins: undefined,
    userName: undefined,
    userRoles: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class UserRecord extends Record(defaultValues) implements User {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<User>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (CollectionUtils.hasValues(params.groupUsers)) {
            params.groupUsers = RecordUtils.ensureRecords(params.groupUsers, GroupUserRecord);
        }

        if (params.organization != null) {
            params.organization = RecordUtils.ensureRecord(params.organization, OrganizationRecord);
        }

        if (params.provider != null) {
            params.provider = RecordUtils.ensureRecord(params.provider, ProviderRecord);
        }

        if (params.instructorProfile != null) {
            params.instructorProfile = RecordUtils.ensureRecord(
                params.instructorProfile,
                InstructorProfileRecord
            );
        }

        if (CollectionUtils.hasValues(params.userLogins)) {
            params.userLogins = RecordUtils.ensureRecords(params.userLogins, UserLoginRecord);
        }

        if (CollectionUtils.hasValues(params.userRoles)) {
            params.userRoles = RecordUtils.ensureRecords(params.userRoles, UserRoleRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public invitePending(): boolean {
        return !StringUtils.hasValue(this.externalIdentifier);
    }

    public displayName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public getFirstAndLastName(): string {
        return `${this.firstName ?? ""} ${this.lastName ?? ""}`.trim();
    }

    public getFirstAndLastNameOrEmail(): string {
        return this.hasFirstName() || this.hasLastName()
            ? this.getFirstAndLastName()
            : this.email ?? "";
    }

    public getLastLoginText(): string {
        return DateUtils.formatDateTime(true, this.lastLogin);
    }

    public hasFirstName(): boolean {
        return StringUtils.hasValue(this.firstName);
    }

    public hasLastName(): boolean {
        return StringUtils.hasValue(this.lastName);
    }

    public initials(): string {
        if (!this.hasFirstName() || !this.hasLastName()) {
            return "";
        }

        const firstInitial = this.firstName[0] ?? "";
        const lastInitial = this.lastName[0] ?? "";

        return `${firstInitial}${lastInitial}`.toUpperCase();
    }

    public with(values: Partial<User>): UserRecord {
        return new UserRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserRecord };

// #endregion Exports
