import { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input/input";
import { CollectionUtils, RecordUtils } from "andculturecode-javascript-core";
import { ContractRecord } from "../contracts/contract-record";
import { DateUtils } from "utilities/date-utils";
import { Event } from "models/interfaces/events/event";
import { EventChecklistRecord } from "./event-checklist-record";
import { EventDayRecord } from "./event-day-record";
import { EventScheduleExceptionRecord } from "./event-schedule-exception-record";
import { EventType } from "models/enumerations/events/event-type";
import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import { InstructorRecord } from "../instructors/instructor-record";
import { ProductRecord } from "../products/product-record";
import { ProviderRecord } from "../providers/provider-record";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { Record } from "immutable";
import { StringUtils } from "utilities/string-utils";
import { UserRecord } from "../users/user-record";
import { DateTime } from "luxon";
import { t } from "utilities/localization/t";
import { NumberUtils } from "utilities/number-utils";
import { ProductVersionRecord } from "../products/product-version-record";
import { ManualLinkTrialDetails } from "models/interfaces/link-trial/manual-link-trial-details";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Event = {
    additionalAddressDetails: undefined,
    additionalDetails: undefined,
    additionalRegistrationRequirements: undefined,
    addressLineOne: undefined,
    addressLineTwo: undefined,
    canceledBy: undefined,
    canceledById: undefined,
    canceledOn: undefined,
    cancellationMessage: undefined,
    ceuOverride: undefined,
    city: undefined,
    contract: undefined,
    country: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    email: undefined,
    endDateDayOfTheMonth: undefined,
    endDateMonth: undefined,
    endDateYear: undefined,
    eventChecklist: undefined,
    eventDays: undefined,
    eventScheduleException: undefined,
    hasAllDaySessions: false,
    hasBeenInvoiced: false,
    hasNoRegistrationLimit: false,
    hideContactInformation: undefined,
    hideLocationInformation: false,
    id: undefined,
    instructor: undefined,
    instructorConfirmed: false,
    instructorId: undefined,
    instructorLedTrainingType: undefined,
    invoiceNotes: "",
    isProvidedByNFPA: true,
    linkFreeTrialContractId: undefined,
    linkFreeTrialToken: undefined,
    locationName: undefined,
    maxRegistrations: undefined,
    meetingUrl: "",
    name: "",
    organization: undefined,
    organizationId: undefined,
    phoneNumber: undefined,
    product: undefined,
    productId: undefined,
    productVersion: undefined,
    productVersionId: undefined,
    provider: undefined,
    providerId: undefined,
    publishedById: undefined,
    publishedOn: undefined,
    publishOnNFPACalendar: undefined,
    registrationUrl: undefined,
    startDateDayOfTheMonth: undefined,
    startDateMonth: undefined,
    startDateYear: undefined,
    state: undefined,
    status: 2,
    timeZone: undefined,
    type: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    useDefaultContactInformation: undefined,
    zipCode: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EventRecord extends Record(defaultValues) implements Event {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Event>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.eventScheduleException != null) {
            params.eventScheduleException = RecordUtils.ensureRecord(
                params.eventScheduleException,
                EventScheduleExceptionRecord
            );
        }

        if (params.canceledBy != null) {
            params.canceledBy = RecordUtils.ensureRecord(params.canceledBy, UserRecord);
        }

        if (params.contract != null) {
            params.contract = RecordUtils.ensureRecord(params.contract, ContractRecord);
        }

        if (params.productVersion != null) {
            params.productVersion = RecordUtils.ensureRecord(
                params.productVersion,
                ProductVersionRecord
            );
        }

        if (params.product != null) {
            params.product = RecordUtils.ensureRecord(params.product, ProductRecord);
        }

        if (params.instructor != null) {
            params.instructor = RecordUtils.ensureRecord(params.instructor, InstructorRecord);
        }

        if (params.provider != null) {
            params.provider = RecordUtils.ensureRecord(params.provider, ProviderRecord);
        }

        if (params.eventChecklist != null) {
            params.eventChecklist = RecordUtils.ensureRecord(
                params.eventChecklist,
                EventChecklistRecord
            );
        }

        if (CollectionUtils.hasValues(params.eventDays)) {
            params.eventDays = RecordUtils.ensureRecords(params.eventDays, EventDayRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to determine if the event is configured with a manual LiNK Trial.
     * @returns Whether or not the event is configured with a manual LiNK Trial.
     */
    public isManualLinkTrial(): boolean {
        return (
            StringUtils.hasValue(this.linkFreeTrialToken) &&
            this.linkFreeTrialContractId != null &&
            this.linkFreeTrialContractId > 0
        );
    }

    /**
     * Convenience method to get the manual LiNK Trial details for the event.
     * @returns ManualLinkTrialDetails with the required id/token OR undfined if not a manual LiNK Trial.
     */
    public getManualLinkTrialDetails(): ManualLinkTrialDetails | undefined {
        if (!this.isManualLinkTrial()) {
            return undefined;
        } else {
            return {
                id: this.linkFreeTrialContractId!,
                token: this.linkFreeTrialToken!,
            };
        }
    }

    /**
     * Convenience method to determine if the event offers a LiNK free trial.
     * @returns {boolean} Whether or not the event has a LiNK free trial
     */
    public hasLinkFreeTrial(): boolean {
        return (
            this.isManualLinkTrial() ||
            this.instructorLedTrainingType === InstructorLedTrainingType.LiveVirtual ||
            this.type === EventType.EducationNetwork
        );
    }

    /**
     * Convenience method to return the Event End Date as a Date.
     *
     * @returns {Date | null} The Event End Date if the data is available, null otherwise.
     */
    public endDate(): Date | null {
        if (
            this.endDateYear == null ||
            this.endDateMonth == null ||
            this.endDateDayOfTheMonth == null
        ) {
            return null;
        }

        return new Date(this.endDateYear, this.endDateMonth, this.endDateDayOfTheMonth);
    }

    /**
     * Convenience method to the return whether the event is in the past
     */
    public isAPastEvent(): boolean {
        const endDate = this.endDate();
        return endDate != null && endDate < DateUtils.getCurrentDateWithoutTime();
    }

    /**
     * Convenience method to determine if the event is canceled.
     *
     * @returns {boolean} Whether or not the event is cancelec
     */
    public isCanceled(): boolean {
        return this.status === PublishStatus.Canceled;
    }

    /**
     * Convenience method to get the host email address for an event.
     *
     * @returns {string} The email address for the event.
     */
    public getHostEmailAddress(): string {
        if (this.useDefaultContactInformation) {
            return this.getDefaultHostEmailAddress();
        } else {
            return this.email ?? t("doubleDash");
        }
    }

    /**
     * Convenience method to get the host email address for an event.
     *
     * @returns {string} The email address for the event.
     */
    public getDefaultHostEmailAddress(): string {
        if (this.type === EventType.Contract) {
            return this.organization?.email ?? t("doubleDash");
        } else if (this.type === EventType.EducationNetwork) {
            return this.provider?.email ?? t("doubleDash");
        } else {
            return "CUSTSERV@NFPA.ORG";
        }
    }

    /**
     * Convenience method to get the host name for an event.
     *
     * @returns {string} The host name for the event.
     */
    public getHostName(): string {
        switch (this.type) {
            case EventType.NFPACatalog:
                return t("nfpa");
            case EventType.Contract:
                return this.organization?.name ?? t("doubleDash");
            case EventType.EducationNetwork:
                return this.provider?.name ?? t("doubleDash");
        }
        return t("doubleDash");
    }
    /**
     * Convenience method to get the host support name for an event.
     *
     * @returns {string} The host support name for the event.
     */
    public getHostSupportName(): string {
        if (this.organizationId != null && this.organizationId > 0) {
            return this.organization?.name ?? t("doubleDash");
        } else if (this.providerId != null && this.providerId > 0) {
            return this.provider?.name ?? t("doubleDash");
        } else {
            return t("nfpa");
        }
    }

    /**
     * Convenience method to get the host phone for an event.
     *
     * @returns {string} The phone for the event.
     */
    public getHostPhone(): string {
        if (this.useDefaultContactInformation) {
            return this.getDefaultHostPhone();
        } else {
            return StringUtils.valueOrDefault(this.phoneNumber, t("doubleDash"));
        }
    }

    /**
     * Convenience method to get the host phone for an event.
     *
     * @returns {string} The phone for the event.
     */
    public getDefaultHostPhone(): string {
        if (this.type === EventType.Contract) {
            return StringUtils.valueOrDefault(this.organization?.phone, t("doubleDash"));
        } else if (this.type === EventType.EducationNetwork) {
            return StringUtils.valueOrDefault(this.provider?.phoneNumber, t("doubleDash"));
        } else {
            return t("doubleDash");
        }
    }

    /**
     * Convenience method to determine if the details on this record are complete.
     *
     * @returns {boolean} Whether or not the details are complete
     */
    public detailsAreComplete(): boolean {
        if (this.type === EventType.NFPACatalog) {
            return this.productId != null && this.contactInformationIsComplete();
        } else if (this.type === EventType.Contract) {
            return (
                this.contractIsComplete() &&
                this.productId != null &&
                this.contactInformationIsComplete() &&
                this.organizationId != null
            );
        } else {
            return (
                this.providerId != null &&
                this.productId != null &&
                this.contactInformationIsComplete()
            );
        }
    }

    /**
     * Convenience method to determine if the contact details on this record is complete.
     *
     * @returns {boolean} Whether or not the contact is complete
     */
    public contactInformationIsComplete(): boolean {
        return (
            this.useDefaultContactInformation ||
            (this.validatePhoneNumber() && StringUtils.isValidEmail(this.email)) ||
            !!this.hideContactInformation
        );
    }

    /**
     * Convenience method to determine if the contract information on this record is complete.
     *
     * @returns {boolean} Whether or not the contract is complete
     */
    public contractIsComplete(): boolean {
        return (
            this.contract?.id != null &&
            this.contract.contractDate != null &&
            StringUtils.hasValue(this.contract.contractNumber) &&
            StringUtils.hasValue(this.contract.nfpaAdminName) &&
            StringUtils.hasValue(this.contract.nfpaAdminEmail) &&
            StringUtils.hasValue(this.contract.nfpaAdminPhone) &&
            (this.contract.noEnrollmentLimit === true ||
                (this.contract.enrollmentLimit != null && this.contract.enrollmentLimit > 0))
        );
    }

    /**
     * Convenience method to determine if the details on this record are complete.
     *
     * @returns {boolean} Whether or not the details are complete
     */
    public detailsAENAreComplete(): boolean {
        return StringUtils.hasValue(this.name) && StringUtils.hasValue(this.timeZone);
    }

    /**
     * Returns whether or not the Event has started.
     *
     * @returns True if todays date is on or after the first day of the Event, false otherwise.
     */
    public eventHasStarted(): boolean {
        const firstDate: Date | null = this.getFirstEventDate();

        if (firstDate == null) {
            return false;
        }

        const midnightOfLastDayInEventTimeZone = DateTime.fromObject(
            {
                day: firstDate.getDate(),
                month: firstDate.getMonth() + 1,
                year: firstDate.getFullYear(),
                hour: 0,
                minute: 1,
                second: 0,
            },
            { zone: this.timeZone }
        );

        return (
            this.currentTimeInEventTimeZone.toMillis() > midnightOfLastDayInEventTimeZone.toMillis()
        );
    }

    /**
     * Returns whether or not the last day of the Event has started.
     *
     * @returns True if todays date is on or after the last day of the Event, false otherwise.
     */
    public lastDayHasStarted(): boolean {
        const lastDate: Date | null = this.getLastEventDate();

        if (lastDate == null) {
            return false;
        }

        const midnightOfLastDayInEventTimeZone = DateTime.fromObject(
            {
                day: lastDate.getDate(),
                month: lastDate.getMonth() + 1,
                year: lastDate.getFullYear(),
                hour: 0,
                minute: 1,
                second: 0,
            },
            { zone: this.timeZone }
        );

        return (
            this.currentTimeInEventTimeZone.toMillis() > midnightOfLastDayInEventTimeZone.toMillis()
        );
    }

    public get currentTimeInEventTimeZone(): DateTime {
        return DateTime.fromObject({}, { zone: this.timeZone });
    }

    /**
     * Return first event day if any
     *
     * @returns Date for first event day.
     */
    public getFirstEventDate(): Date | null {
        if (CollectionUtils.isEmpty(this?.eventDays)) {
            return null;
        }

        // Sort the event days.
        this.eventDays!.sort((a: EventDayRecord, b: EventDayRecord) => {
            return new Date(a.eventDate()).getTime() - new Date(b.eventDate()).getTime();
        });

        // return the first date in the array.
        return new Date(this.eventDays[0].eventDate());
    }

    /**
     * Return date text for the first event day if any
     *
     * @returns Date string for first event day.
     */
    public getFirstEventDateText(): string {
        const firstEventDate: Date | null = this.getFirstEventDate();

        if (firstEventDate == null) {
            return t("doubleDash");
        }

        return DateUtils.formatDateCustom(firstEventDate.toString(), t("shortDateFormat"));
    }

    /**
     * Return last day of event
     *
     * @returns Date string for last event day.
     */
    public getLastEventDate(): Date | null {
        if (CollectionUtils.isEmpty(this?.eventDays)) {
            return null;
        }

        // Sort the event days.
        this.eventDays!.sort((a: EventDayRecord, b: EventDayRecord) => {
            return new Date(b.eventDate()).getTime() - new Date(a.eventDate()).getTime();
        });

        // return the first date in the array as a date without any time.
        return new Date(new Date(this.eventDays[0].eventDate()).toDateString());
    }

    /**
     * Return formatted phone number
     * Ex: 234.567.9225
     * @returns Return formatted phone number
     */
    public formatPhoneNumber(): string {
        if (this.phoneNumber) {
            let format = this.phoneNumber.split("");
            format.splice(0, 2);
            format.splice(3, 0, ") ");
            format.splice(7, 0, "-");

            return "(" + format.join("");
        }
        return "";
    }

    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on YYYY-MM-DDTHH:MM:SS:FFFFFFF+Offset
     * 2022-10-20T17:42:30.7005991+00:00

     */
    public getLastUpdated(): string {
        return StringUtils.hasValue(this.updatedOn) ? this.updatedOn : this.createdOn ?? "";
    }

    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on MM/DD/YY at 8:00 AM
     */
    public getLastUpdatedText(): string {
        return DateUtils.formatLastEditedDate(this, true);
    }

    /**
     * Convenience method to the return the last updated time in milliseconds.
     * Ex: 1666287750700 for 2022-10-20T17:42:30.7005991+00:00
     */

    public getLastUpdatedInMilliseconds(): number {
        const lastUpdated = this.getLastUpdated();
        return DateUtils.convertToMilliseconds(lastUpdated);
    }

    /**
     * Convenience method to create display text for the provider selection
     *
     * @returns {string}
     *  "NFPA" when event is provided by NFPA
     *  Provider's name when the event is provided by a provider org.
     *  Unassigned when the event is configured to be provided by a provider org,
     *  and the org has not been selected yet.
     */
    public getProviderDescription(): string {
        if (this.isProvidedByNFPA) {
            return t("nfpa");
        }

        if (StringUtils.hasValue(this.provider?.name)) {
            return this.provider!.name;
        }

        return t("unassigned");
    }

    /**
     * Convenience method to determine if the meetingUrl on this record is complete.
     *
     * @returns {boolean} Whether or not the meetingUrl is complete
     */
    public inPersonLocationIsComplete(): boolean {
        return (
            StringUtils.hasValue(this.addressLineOne) ||
            StringUtils.hasValue(this.city) ||
            StringUtils.hasValue(this.country) ||
            StringUtils.hasValue(this.locationName) ||
            StringUtils.hasValue(this.state) ||
            StringUtils.hasValue(this.zipCode) ||
            this.hideLocationInformation
        );
    }

    /**
     * Convenience method to return the Event Start Date as a Date.
     *
     * @returns {Date | null} The Event Start Date if the data is available, null otherwise.
     */
    public startDate(): Date | null {
        if (
            this.startDateYear == null ||
            this.startDateMonth == null ||
            this.startDateDayOfTheMonth == null
        ) {
            return null;
        }

        return new Date(this.startDateYear, this.startDateMonth, this.startDateDayOfTheMonth);
    }

    /**
     * Convenience method to determine if the meetingUrl on this record is complete.
     *
     * @returns {boolean} Whether or not the meetingUrl is complete
     */
    public validateInPersonLocationForm(): Array<string> {
        // let isValid = true;
        const formValidationErrorMessages = [];

        if (!StringUtils.hasValue(this.addressLineOne)) {
            formValidationErrorMessages.push(t("addressLine1IsRequired"));
            // isValid = false;
        }

        if (!StringUtils.hasValue(this.city)) {
            formValidationErrorMessages.push(t("cityIsRequired"));
            // isValid = false;
        }

        if (!StringUtils.hasValue(this.country)) {
            formValidationErrorMessages.push(t("countryIsRequired"));
            // isValid = false;
        }

        if (!StringUtils.hasValue(this.locationName)) {
            formValidationErrorMessages.push(t("locationNameIsRequired"));
            // isValid = false;
        }

        if (!StringUtils.hasValue(this.state)) {
            formValidationErrorMessages.push(t("stateIsRequired"));
            // isValid = false;
        }

        if (!StringUtils.hasValue(this.zipCode)) {
            formValidationErrorMessages.push(t("zipCodeIsRequired"));
            // isValid = false;
        }

        return formValidationErrorMessages;
    }

    /**
     * Convenience method to determine the phone number is validate.
     * @returns {boolean} Whether or not the phoneNumber is valid
     */
    public validatePhoneNumber(): boolean {
        if (!StringUtils.hasValue(this.phoneNumber)) {
            return false;
        }

        const phoneNumber = parsePhoneNumber(this.phoneNumber);
        if (phoneNumber == null) {
            return false;
        }

        return isPossiblePhoneNumber(this.phoneNumber!);
    }

    /**
     * Convenience method to determine if the in person location on this record is complete.
     *
     * @returns {boolean} Whether or not the in person location is complete
     */
    public locationIsComplete(): boolean {
        return (
            (this.instructorLedTrainingType === InstructorLedTrainingType.InPerson &&
                this.inPersonLocationIsComplete()) ||
            (this.instructorLedTrainingType === InstructorLedTrainingType.LiveVirtual &&
                StringUtils.hasValue(this.meetingUrl))
        );
    }

    public isLive(): boolean | undefined {
        if (this.status !== PublishStatus.Live) {
            return false;
        }

        const lastEventDate = this.getLastEventDate();
        if (lastEventDate == null) {
            return undefined;
        }

        const currentDate = new Date(new Date().toDateString());
        if (currentDate <= lastEventDate) {
            return true;
        } else {
            return false;
        }
    }

    public isComplete(): boolean {
        return (this.status === PublishStatus.Live && !this.isLive()) ?? false;
    }

    /**
     * Convenience method to determine if the max credit hours permitted by the
     * productVersion for this event have been exceeded
     * @returns {boolean} Whether or not the max credit hours have been exceeded
     */
    public maxCreditHoursExceeded(): boolean {
        const productMaxCreditHours = this.getMaxCreditHoursPermitted();
        const eventCreditHours = this.getCreditHours();

        return eventCreditHours > productMaxCreditHours;
    }

    /**
     * Convenience method to determine if the schedule on this record is complete.
     * A Schedule is complete when:
     *  1 or more Event Days exist, all having a Date and Sessions with State/End times.
     *  Credit Hours < Reccommended Product Hours OR Exception Has been Granted.
     * @returns {boolean} Whether or not the schedule is complete
     */
    public scheduleIsComplete(): boolean {
        if (this.eventDays == null || this.eventDays.length === 0) {
            return false;
        }

        const creditHoursExceeded = this.maxCreditHoursExceeded();
        const exceptionGranted = this.eventScheduleException?.granted;

        if (
            this.productId == null ||
            this.productId <= 0 ||
            (creditHoursExceeded && !exceptionGranted)
        ) {
            return false;
        }

        // Events with All Day Session don't require sessions,
        // they just need to have at least one day.
        if (this.hasAllDaySessions) {
            return this.eventDays != null && this.eventDays.length > 0;
        }

        if (!StringUtils.hasValue(this.timeZone)) {
            return false;
        }

        // If we reach here, then we need to validate that a valid event schedule exists.
        const invalidEventDay = this.eventDays.find(
            (ed) =>
                ed.eventDate() == null ||
                ed.eventSessions == null ||
                ed.eventSessions.length === 0 ||
                ed.eventSessions.find(
                    (session) => session.startTime() == null || session.endTime() == null
                )
        );
        return invalidEventDay == null;
    }

    public getCeusDisplay(): string {
        return NumberUtils.formatCEUsFromCreditHours(this.getCreditHours());
    }

    /**
     * Convenience method to get the CEUs for the event.
     *
     * CEUs are determined either from manual entry on ceuOverride or calculated from the productVersion.CreditHours
     *
     * @returns {number} The CEUs for the event.
     */
    public getCeus(): number {
        return this.ceuOverride ?? this.getCreditHours() / 10;
    }

    public getCreditHours(): number {
        return this.ceuOverride ? this.ceuOverride * 10 : this.getRecommendedCreditHours();
    }

    /**
     * Convenience method to determine if the instructor on this record is complete.
     *
     * @returns {boolean} Whether or not the instructor is complete
     */
    public instructorIsComplete(): boolean {
        return this.instructorId != null && this.instructorId > 0 && this.instructorConfirmed;
    }

    /**
     * Convenience method to determine if the Event is a Draft.
     *
     * @returns {boolean} Whether or not the Event is a Draft.
     */
    public isDraft(): boolean {
        return (
            this.status === PublishStatus.Draft &&
            this.publishedOn == null &&
            this.canceledOn == null
        );
    }

    /**
     * Convenience method to determine if the product on this record is complete.
     *
     * @returns {boolean} Whether or not the product is complete
     */
    public productIsComplete(): boolean {
        return this.productId != null && this.productId > 0;
    }

    /**
     * Convenience method to determine if registration on this record is complete.
     *
     * @returns {boolean} Whether or not registration is complete
     */
    public registrationIsComplete(): boolean {
        if (this.type === null) {
            return false;
        }

        if (this.type === EventType.NFPACatalog) {
            return this.maxRegistrations != null || this.hasNoRegistrationLimit;
        }

        if (this.type === EventType.EducationNetwork) {
            return (
                this.publishOnNFPACalendar != null &&
                (StringUtils.hasValue(this.registrationUrl) || !this.publishOnNFPACalendar)
            );
        }

        return true;
    }

    /**
     * Convenience method to get the recommended credit hours for the
     * productVersion associated with the event.
     *
     * @returns {number} credit hours
     */
    public getRecommendedCreditHours(): number {
        if (this.productVersionId == null) {
            return this.product?.latestProductVersion?.creditHours ?? 0;
        } else {
            return this.productVersion?.creditHours ?? 0;
        }
    }

    /**
     * Convenience method to get the recommended credit hours for the
     * productVersion associated with the event.
     *
     * @returns {number} credit hours
     */
    public getRecommendedCEUs(): number {
        return this.getRecommendedCreditHours() / 10;
    }

    /**
     * Convenience method to get the max CEUs permitted for the
     * productVersion associated with the event.
     *
     * NFPA currently permits up to 2x the recommended CEUs.
     *
     * @returns {number} CEUs * 2
     */
    public getMaxCEUsPermitted(): number {
        return this.getRecommendedCEUs() * 2;
    }

    /**
     * Convenience method to get the max credit hours permitted for the
     * productVersion associated with the event.
     *
     * NFPA currently permits up to 2x the recommended credit hours.
     *
     * @returns {number} credit hours * 2
     */
    public getMaxCreditHoursPermitted(): number {
        return this.getRecommendedCreditHours() * 2;
    }

    /**
     * Given a set of values for Event properties, create a new EventRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Event>} values The values to overwrite on this instance.
     * @returns A EventRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<Event>): EventRecord {
        return new EventRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventRecord };

// #endregion Exports
