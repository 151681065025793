import { Auditable } from "andculturecode-javascript-core";
import moment from "moment";
import { t } from "utilities/localization/t";
import { StringUtils } from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Utility Functions
// -------------------------------------------------------------------------------------------------

/**
 * Given a datetime as a string, returns a long representing the datetime as
 * milliseconds since the Epoch.
 * If the datetime provided is blank or null, treat it as 0 seconds since the Epoch (0).
 * @param timestamp the datetime to convert
 * @param trunctateTime whether to exclude time from the conversion
 */
const convertToMilliseconds = (timestamp: string, trunctateTime = false): number => {
    if (!StringUtils.hasValue(timestamp)) {
        return 0;
    }

    const date = trunctateTime ? new Date(new Date(timestamp).toDateString()) : new Date(timestamp);
    return date.getTime();
};

const formatDateTime = (monthFirst: boolean, value?: string, includeAt: boolean = true): string => {
    if (value === undefined) {
        return "";
    }
    const date = moment(value);
    if (date == null || !date.isValid()) {
        return "";
    }

    const formattedDate = monthFirst ? date.format("MM/DD/YY") : date.format("YYYY/M/D");
    const middle: string = includeAt ? t("spaceAtSpace") : " ";

    return `${formattedDate}${middle}${date.format("h:mm a")}`;
};

const formatDate = (monthFirst: boolean, value?: string): string => {
    if (value === undefined) {
        return "";
    }
    const date = moment(value);
    if (date == null || !date.isValid()) {
        return "";
    }
    const formattedDate = monthFirst ? date.format("MM/DD/YY") : date.format("YYYY/M/D");
    return formattedDate;
};

const formatDateCustom = (value?: string, format: string = "MM/DD/YY"): string => {
    if (value === undefined) {
        return "";
    }

    const date = moment(value);
    if (date == null || !date.isValid()) {
        return "";
    }

    const formattedDate = date.format(format);
    return formattedDate;
};

/**
 * Given an Auditable, returns a string representing the last date
 * the record was updated. If the record has never been saved
 * (i.e. updatedOn and createdOn are both null or empty),
 * returns an empty string.
 * @param record
 */
const formatLastEditedDate = (record: Auditable, monthFirst: boolean): string => {
    if (StringUtils.isEmpty(record.createdOn) && StringUtils.isEmpty(record.updatedOn)) {
        return "";
    }

    return formatDate(
        monthFirst,
        StringUtils.hasValue(record.updatedOn) ? record.updatedOn : record.createdOn
    );
};

/**
 * Given an Auditable, returns a string representing the last date and time
 * the record was updated. If the record has never been saved
 * (i.e. updatedOn and createdOn are both null or empty),
 * returns an empty string.
 * @param record
 */
const formatLastEditedDateTime = (
    record: Auditable,
    monthFirst: boolean,
    includeAt: boolean = true
): string => {
    if (StringUtils.isEmpty(record.createdOn) && StringUtils.isEmpty(record.updatedOn)) {
        return "";
    }

    return formatDateTime(
        monthFirst,
        StringUtils.hasValue(record.updatedOn) ? record.updatedOn : record.createdOn,
        includeAt
    );
};

const getCurrentDateWithoutTime = (): Date => {
    return new Date(new Date().setHours(0, 0, 0, 0));
};

/**
 * Given a Date, return a Date with the same calendar date and time, but with no timezone offset.
 *
 * @param {Date} date The date to remove the timezone offset from.
 * @returns {Date} A new Date with the same calendar date and time, but with no timezone offset.
 */
const removeTimezoneOffset = (date: Date): Date => {
    var formattedDate = new Date(date);

    return new Date(
        Date.UTC(
            formattedDate.getFullYear(),
            formattedDate.getMonth(),
            formattedDate.getDate(),
            formattedDate.getHours(),
            formattedDate.getMinutes(),
            formattedDate.getSeconds(),
            formattedDate.getMilliseconds()
        )
    );
};

/** Given 2 Dates, calculate the days between them. */
const daysBetween = (date1: Date, date2: Date): number => {
    return Math.floor((new Date(date1).getTime() - date2.getTime()) / (1000 * 60 * 60 * 24));
};

// #endregion Utility Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const DateUtils = {
    convertToMilliseconds: convertToMilliseconds,
    formatDate: formatDate,
    formatDateCustom: formatDateCustom,
    formatDateTime: formatDateTime,
    formatLastEditedDate: formatLastEditedDate,
    formatLastEditedDateTime: formatLastEditedDateTime,
    getCurrentDateWithoutTime: getCurrentDateWithoutTime,
    removeTimezoneOffset: removeTimezoneOffset,
    daysBetween: daysBetween,
};

export { DateUtils };

// #endregion Exports
