import React from "react";
import { RoleType } from "models/enumerations/users/role-type";
import "./report-selection.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportSelectionProps {
    adminOnly: JSX.Element;
    roleType?: RoleType | null;
    title: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "report-selection";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportSelection: React.FC<React.PropsWithChildren<ReportSelectionProps>> = (
    props: React.PropsWithChildren<ReportSelectionProps>
): JSX.Element => {
    const { adminOnly, roleType } = props;

    const showAdminOnlySection =
        roleType === RoleType.NfpaAdministrator ||
        roleType === RoleType.NfpaOperator ||
        roleType === RoleType.NfpaSupport;

    if (showAdminOnlySection) {
        return (
            <>
                <h2>{props.title}</h2>
                {adminOnly}
            </>
        );
    }

    return (
        <div className={CSS_CLASS_NAME}>
            {showAdminOnlySection && (adminOnly)}
            <h2>{props.title}</h2>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportSelection };

// #endregion Exports
