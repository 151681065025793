import React, { useReducer, useEffect, useMemo } from "react";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { Icons } from "components/icons/constants/icons";
import { HeaderBanner, HeaderBannerNavItem } from "components/header-banner/header-banner";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText } from "components/empty-text/empty-text";
import { FormSelect } from "components/form/form-select/form-select";
import { HeaderBannerUtils } from "utilities/header-banner-utils";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { Pager } from "components/pager/pager";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { Badge } from "components/badges/badge/badge";
import { useUsers } from "utilities/hooks/models/users/use-users";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { RoleType, RoleTypeDisplayNames } from "models/enumerations/users/role-type";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { NumberUtils } from "utilities/number-utils";
import { userSearchReducer } from "./user-search-reducer";
import { CollectionUtils } from "utilities/collection-utils";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ScrollUtils } from "utilities/scroll-utils";
import { StringUtils } from "utilities/string-utils";
import { UserRoleRecord } from "models/view-models/user-roles/user-role-record";
import "./user-list-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserListPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-list-page";
const ITEMS_PER_PAGE = 20;
const DEBOUNCE_TIME = 750;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserListPage: React.FC<UserListPageProps> = validatePageAccess(
    AccessControlKeys.UserListPage
)((): JSX.Element => {
    const navItems: HeaderBannerNavItem[] = HeaderBannerUtils.getUserManagementNavItems();

    const [{ searchText, debouncedSearchText, roleType, hasNoUserRoles, currentPage }, dispatch] =
        useReducer(userSearchReducer, { searchText: "", currentPage: 1 });

    const roleTypes = useMemo(
        () => (roleType == null || roleType === -1 ? undefined : [roleType as RoleType]),
        [roleType]
    );
    const {
        users,
        rowCount: userCount,
        isLoading,
    } = useUsers({
        hasNoUserRoles: hasNoUserRoles,
        roleTypes: roleTypes,
        searchText: debouncedSearchText,
        includeLastLogin: true,
        includeRoles: true,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const handleSearchTextInputChange = (search: string) => {
        dispatch({
            type: "update_search",
            value: search,
        });
    };

    const handleSearchTriggered = (debouncedSearchText: string): void => {
        dispatch({
            type: "update_debounced_search",
            value: debouncedSearchText,
        });
    };

    const onClearFilterClick = () => {
        dispatch({
            type: "clear_search_criteria",
        });
    };

    const onPageClick = (pageNumber: number) => {
        dispatch({
            type: "update_currentPage",
            value: pageNumber,
        });
    };

    const handleRoleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const valueAsInt = NumberUtils.parseInt(event.target.value);
        dispatch({
            type: "update_roleFilter",
            value: valueAsInt,
        });
    };

    const getViewUserPath = (userId: number) => {
        return RouteUtils.replacePathParams(sitemap.admin.userManagement.users.info.default, {
            id: userId,
        });
    };

    useEffect(() => {
        ScrollUtils.scrollToElementBySelector(`.content`);
    }, [users]);

    return (
        <div className={CSS_CLASS_NAME}>
            <HeaderBanner navItems={navItems} title={t("userManagement")}>
                <ButtonIcon
                    text={t("addNewUser")}
                    buttonSize={ButtonSize.Medium}
                    buttonStyle={ButtonStyle.TertiaryAlt}
                    buttonType={ButtonType.Link}
                    iconType={Icons.Plus}
                    linkPath={sitemap.admin.userManagement.users.new}
                />
            </HeaderBanner>
            <SideContentLeftLayout
                sidebarElement={
                    <div className={`${CSS_CLASS_NAME}__sidebar`}>
                        <Paragraph style={ParagraphStyle.Label}>{t("filterResults")}</Paragraph>

                        <div className={`${CSS_CLASS_NAME}__sidebar__search`}>
                            <SearchTextInput
                                debounce={DEBOUNCE_TIME}
                                disabled={isLoading}
                                onSearchTextInputChange={handleSearchTextInputChange}
                                onSearchTriggered={handleSearchTriggered}
                                id={"userSearch"}
                                placeholder={t("searchByEmailNameOrId")}
                                searchTextInputValue={searchText}
                            />
                        </div>
                        <FormSelect
                            disabled={isLoading}
                            ariaLabelledBy={t("filterByRole")}
                            formFieldName={t("filterByRole")}
                            id="userFilterByRole"
                            label={t("filterByRole")}
                            onChange={handleRoleTypeChange}
                            options={[
                                ...EnumUtils.numericEnumToSelectOptions(
                                    RoleType,
                                    RoleTypeDisplayNames
                                ),
                                { value: "-1", text: "Unassigned" },
                            ]}
                            value={roleType?.toString()}
                        />
                        <Button
                            disabled={isLoading}
                            onClick={onClearFilterClick}
                            size={ButtonSize.Small}
                            style={ButtonStyle.Quaternary}
                            text={t("clearAllFilters")}
                        />
                    </div>
                }>
                <div className="content-wrap">
                    <div className="content">
                        <SkipNavContent>
                            {users?.length > 0 && (
                                <DataTable>
                                    <thead>
                                        <tr>
                                            <th className="id">{t("id")}</th>
                                            <th className="name">{t("name")}</th>
                                            <th className="email">{t("email")}</th>
                                            <th className="netsuiteId">{t("netsuiteId")}</th>
                                            <th className="role">{t("role")}</th>
                                            <th className="last-login">{t("lastLoginDate")}</th>
                                            <th className="action">
                                                <span className="sr-only">{t("action")}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user) => (
                                            <tr key={`user-row-${user.id}`}>
                                                <td className="id">{user.id}</td>
                                                <td className="name">
                                                    {StringUtils.valueOrDefault(
                                                        user.getFirstAndLastName()
                                                    )}
                                                </td>
                                                <td className="email">{user.email}</td>
                                                <td className="netsuiteId">
                                                    {user.netsuiteId ?? "--"}
                                                </td>
                                                <td className="role">
                                                    <div className="user-roles">
                                                        {!CollectionUtils.hasValues(
                                                            user.userRoles
                                                        ) && <Badge text={t("unassigned")} />}
                                                        {user.userRoles?.map(
                                                            (userRole: UserRoleRecord) => (
                                                                <Badge
                                                                    key={userRole.id}
                                                                    text={userRole.role?.name}
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="last-login">
                                                    {user.invitePending() ||
                                                    CollectionUtils.isEmpty(user.userLogins) ? (
                                                        <Badge text={t("invitePending")} />
                                                    ) : (
                                                        user.getLastLoginText()
                                                    )}
                                                </td>
                                                <td className="action">
                                                    <ContextMenu>
                                                        <ContextMenuAnchor
                                                            hrefPath={getViewUserPath(user.id ?? 0)}
                                                            displayName={t("viewUserInfo")}
                                                        />
                                                    </ContextMenu>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </DataTable>
                            )}
                            {(users == null || users.length === 0) && (
                                <EmptyText table>{t("noResultsFoundPleaseTryAgain")}</EmptyText>
                            )}
                        </SkipNavContent>
                    </div>
                </div>
                {users != null && users.length > 0 && (
                    <div className="footer">
                        <Pager
                            currentPage={currentPage}
                            totalPages={userCount / ITEMS_PER_PAGE}
                            onPageClick={onPageClick}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={userCount}
                        />
                    </div>
                )}
            </SideContentLeftLayout>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserListPage };

// #endregion Exports
